import {
    GET_STATUS_EXPLANATIONS_REQUEST,
    GET_STATUS_EXPLANATIONS_SUCCESS,
    GET_STATUS_EXPLANATIONS_ERROR,
    GET_STATUS_EXPLANATION_REQUEST,
    GET_STATUS_EXPLANATION_SUCCESS,
    GET_STATUS_EXPLANATION_ERROR,
    CREATE_STATUS_EXPLANATION_REQUEST,
    CREATE_STATUS_EXPLANATION_SUCCESS,
    CREATE_STATUS_EXPLANATION_ERROR,
    EDIT_STATUS_EXPLANATION_REQUEST,
    EDIT_STATUS_EXPLANATION_SUCCESS,
    EDIT_STATUS_EXPLANATION_ERROR,
    DELETE_STATUS_EXPLANATION_REQUEST,
    DELETE_STATUS_EXPLANATION_SUCCESS,
    DELETE_STATUS_EXPLANATION_ERROR
} from "@/actions"

import {
    statusExplanationService
} from "@/services"

const state = {
    statusExplanations: [],
    statusExplanation: {},
    loading: false
}

import { isEmpty, generateKey } from "@/utilities";

const getters = {
    statusExplanations: state => state.statusExplanations,

    statusExpFilterByUuid: state => uuid => state.statusExplanations.find(s => s.uuid == uuid),

    statusExpFilterByStatusUuid: state => uuid => state.statusExplanations.filter(s => s.statuses_uuid == uuid),

    includeApplicant: (state, getters, rootState, rootGetters) => state.statusExplanations.filter(se => se.statuses_uuid == rootGetters['status/applicant']?.uuid && ["application-submitted"].includes(se?.slug))?.map(se => se.uuid) ?? [],

    includeCandidate: (state, getters, rootState, rootGetters) => state.statusExplanations.filter(se => se.statuses_uuid == rootGetters['status/candidate']?.uuid && !["did-not-pass", "candidate-declined", "screening-session-not-accepted"].includes(se?.slug))?.map(se => se.uuid) ?? [],
}

const actions = {
    async getStatusExplanations({ commit }) {
        commit("GET_STATUS_EXPLANATIONS_REQUEST")
        const response = await statusExplanationService.getStatusExplanations();
        const { statusExplanation, data } = response
        switch (statusExplanation) {
            case 200:
                commit("GET_STATUS_EXPLANATIONS_SUCCESS", data)
                break
            default:
                commit("GET_STATUS_EXPLANATIONS_ERROR", data)
        }
        return response
    },
    async getStatusExplanation({ commit }, params) {
        commit("GET_STATUS_EXPLANATION_REQUEST")
        const response = await statusExplanationService.getStatusExplanation(params)
        const { statusExplanation, data } = response
        switch (statusExplanation) {
            case 200:
                commit("GET_STATUS_EXPLANATION_SUCCESS", data)
                break
            default:
                commit("GET_STATUS_EXPLANATION_ERROR", data)
                break
        }
        return response
    },
    async createStatusExplanation({ commit }, params) {
        commit("CREATE_STATUS_EXPLANATION_REQUEST")
        const response = await statusExplanationService.createStatusExplanation(params)
        const { statusExplanation, data } = response
        switch (statusExplanation) {
            case 200:
                commit("CREATE_STATUS_EXPLANATION_SUCCESS", data)
                break
            default:
                commit("CREATE_STATUS_EXPLANATION_ERROR", data)
        }
        return response
    },
    async editStatusExplanation({ commit }, params) {
        commit("EDIT_STATUS_EXPLANATION_REQUEST")
        const response = await statusExplanationService.editStatusExplanation(params)
        const { statusExplanation, data } = response
        switch (statusExplanation) {
            case 200:
                commit("EDIT_STATUS_EXPLANATION_SUCCESS", data)
                break
            default:
                commit("EDIT_STATUS_EXPLANATION_ERROR", data)
        }
        return response
    },
    async deleteStatusExplanation({ commit, dispatch }, uuid) {
        commit("DELETE_STATUS_EXPLANATION_REQUEST");
        const response = await statusExplanationService.deleteStatusExplanation(uuid);
        const { statusExplanation, data } = response;
        switch (statusExplanation) {
            case 200:
                data.success == true ? commit("DELETE_STATUS_EXPLANATION_SUCCESS", { status_explanation: { uuid } }) : null;
                data.success ? dispatch("alert/createAlertSuccess", "Status Explanation deleted.", { root: true }) : null;
                break
            default:
                console.log(data)
                dispatch('alert/createAlertErrors', "Oops! Something went wrong!", { root: true })
                commit("DELETE_STATUS_EXPLANATION_ERROR", data)
        }
        return response
    }
}

const mutations = {
    [GET_STATUS_EXPLANATIONS_REQUEST]: state => {
        state.loading = true
    },
    [GET_STATUS_EXPLANATIONS_SUCCESS]: (state, data) => {
        const statusExplanations = data.status_explanations
        state.statusExplanations = statusExplanations
        state.loading = false
    },
    [GET_STATUS_EXPLANATIONS_ERROR]: state => {
        state.loading = false
        state.error = true
    },
    [GET_STATUS_EXPLANATION_REQUEST]: state => {
        state.loading = true
    },
    [GET_STATUS_EXPLANATION_SUCCESS]: (state, data) => {
        const { status_explanation } = data
        state.statusExplanation = status_explanation
        state.loading = false
    },
    [GET_STATUS_EXPLANATION_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_STATUS_EXPLANATION_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_STATUS_EXPLANATION_SUCCESS]: (state, data) => {
        const { status_explanation } = data;
        state.loading = false;
        state.statusExplanations = addNewFilter(state.statusExplanations, [status_explanation])
    },
    [CREATE_STATUS_EXPLANATION_ERROR]: state => {
        state.loading = false
    },

    [EDIT_STATUS_EXPLANATION_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_STATUS_EXPLANATION_SUCCESS]: state => {
        state.loading = false
        const { status_explanation } = data;
        state.statusExplanations = addNewFilter(state.statusExplanations, [status_explanation])
    },
    [EDIT_STATUS_EXPLANATION_ERROR]: state => {
        state.loading = false
    },
    [DELETE_STATUS_EXPLANATION_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_STATUS_EXPLANATION_SUCCESS]: (state, data) => {
        state.loading = false
        const { status_explanation } = data;
        state.statusExplanations = state.statusExplanations.filter(se => se.uuid != status_explanation.uuid)
    },
    [DELETE_STATUS_EXPLANATION_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const statusExplanation = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}