import {
  indexedDBService
} from "@/services";

import Dexie from 'dexie';

var pluralize = require('pluralize');

import { isEqual, parseISO, parseJSON } from "date-fns";
import { underscoreToCamel, isEmpty } from "@/utilities";

const state = {
  loading: false,
  synced: false
}

const getters = {

}

const actions = {
  async initIndexedDB({ state, commit, dispatch }) {
    if (!state.synced) {
      commit("GET_TABLE_VERSIONS_REQUEST");
      const response = await indexedDBService.getTableVersions();
      const { status, data } = response;
      switch (status) {
        case 200:
          commit("GET_TABLE_VERSIONS_SUCCESS");
          await initDatabase(commit, data);
          commit("STORE_SYNCED");
          break;
        default:
          commit("GET_TABLE_VERSIONS_ERROR")
      }
    }
    return true;
  },
}

const mutations = {
  GET_TABLE_VERSIONS_REQUEST: state => {
    state.loading = true;
  },
  GET_TABLE_VERSIONS_SUCCESS: state => {
    state.loading = false;
  },
  GET_TABLE_VERSIONS_ERROR: state => {
    state.loading = false;
  },
  STORE_SYNCED: state => {
    state.synced = true;
  }
}

export const indexedDB = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

/**
 * 
 * @param {*} commit 
 * @param {*} data 
 * @returns 
 */
async function initDatabase(commit, updateParams) {
  return await Dexie.getDatabaseNames(async function (databaseNames) {
    if (databaseNames.length > 0) {
      var db = new Dexie(databaseNames[0]);
      db.open().then(async function () {
        // Get
        for (let x = 0; x < db.tables.length; x++) {
          let data = await db.tables[x].toArray();
          let table = db.tables[x].name;

          let orderedArr = await db.tables[x]?.orderBy("updated_at").reverse().toArray();

          if (orderedArr?.length != 0) {
            if (
              orderedArr.at(0)?.updated_at !== updateParams[table]
            ) {
              let res = await indexedDBService.getTableData({ tables: [table] });
    
              let resData = res.data;
              data = resData[table];
    
              db.transaction("rw", db.tables[x], function* () {
                yield db.tables[x].bulkDelete(orderedArr.map(i => i.uuid))
                yield db.tables[x].bulkPut(data);
              }).catch(e => {
                console.log(e)
              });
            }
          }

          await populateVuexStore(commit, table, data)
        }
      }).finally(function () {
        db.close();
      });
    } else {
      let syncData = await indexedDBService.getTableData({ tables: Object.keys(updateParams) });

      await createDatabase(commit, syncData.data);

      Object.keys(syncData.data).forEach(k => {
        populateVuexStore(commit, k, syncData.data[k]);
      })
    }
  }).then(() => {
    return true
  });
}

/**
 *  Creates "InstructionalConnections" indexedDB intance
 *
 */
const createDatabase = Dexie.async(function* (commit, data) {
  let insertData = data;
  let keys = Object.keys(insertData);

  var db = new Dexie("InstructionalConnections");

  let database = {};

  for (let x = 0; x < keys.length; x++) {
    let key = keys[x];
    let tableData = insertData[key];
    database[key] = Object.keys(tableData.at(0) ?? {}).toString()
  }

  db.version(1).stores(database);

  let atTables = [
    "created_at",
    "updated_at"
  ];

  try {
    yield db.open();

    for (let y = 0; y < keys.length; y++) {
      let key = keys[y];
      let tData = insertData[key];

      yield db[key].bulkPut(tData);
    }
  } finally {
    yield db.close();
  }
});

/**
 *  Populates Vuex Store With Data from IndexedDB
 *
 */
// eslint-disable-next-line require-yield
const populateVuexStore = async function (commit, table, data) {

  const moduleMap = {
    learningManagementSystem: {
      module: 'lms',
      mutator: 'lmss'
    },
    opms: {
      module: 'opm',
      mutator: 'opms'
    },
    setting: "settings"
  }

  let action = `GET_${table.toUpperCase()}_SUCCESS`;
  let module = underscoreToCamel(pluralize.singular(table));

  if (!isEmpty(moduleMap[module])) {
    const mutator = typeof moduleMap[module] === 'string' ? moduleMap[module] : moduleMap[module].mutator;
    module = typeof moduleMap[module] === 'string' ? moduleMap[module] : moduleMap[module].module;
    action = `GET_${mutator.toUpperCase()}_SUCCESS`;
  }

  let commitData = {};
  
  if(table == 'opms'){
    commitData['data'] = data
  }else{
    commitData[table] = data;
  }
  commit(`${module}/${action}`, commitData, { root: true });
};


// const updateVuexStore = Dexie.async(function (commit, data) {

//   var db = new Dexie("InstructionalConnections");

//   db.open().then(async function () {
//     for (let y = 0; y < db.tables.length; y++) {
//       let orderedArr = await db.tables[y].orderBy("updated_at").reverse().toArray();

//       if (orderedArr?.length != 0) {
//         if (
//           orderedArr.at(0)?.updated_at !== data[db.tables[y].name]
//         ) {

//           let res = await indexedDBService.getTableData({ tables: [db.tables[y].name] });

//           const { data } = res;
//           let tData = data[db.tables[y].name];

//           db.transaction("rw", db.tables[y], function* () {
//             yield db.tables[y].bulkDelete(orderedArr.map(i => i.uuid))
//             yield db.tables[y].bulkPut(tData);
//           }).catch(e => {
//             console.log(e)
//           });

//           await populateVuexStore(commit, db.tables[y].name, tData);
//         }
//       }
//     }
//   }).finally(function () {
//     db.close();
//   });
// })
