import {
    GET_DISCIPLINES_REQUEST,
    GET_DISCIPLINES_SUCCESS,
    GET_DISCIPLINES_ERROR,
    GET_DISCIPLINE_REQUEST,
    GET_DISCIPLINE_SUCCESS,
    GET_DISCIPLINE_ERROR,
    CREATE_DISCIPLINE_REQUEST,
    CREATE_DISCIPLINE_SUCCESS,
    CREATE_DISCIPLINE_ERROR,
    EDIT_DISCIPLINE_REQUEST,
    EDIT_DISCIPLINE_SUCCESS,
    EDIT_DISCIPLINE_ERROR,
    DELETE_DISCIPLINE_REQUEST,
    DELETE_DISCIPLINE_SUCCESS,
    DELETE_DISCIPLINE_ERROR
} from "@/actions"

import {
    disciplineService
} from "@/services"

import { isEmpty, addNewFilter } from "@/utilities"

const state = {
    disciplines: [],
    discipline: {},
    loading: false,
    loadedOnce: true,
    otherDisciplines: []
}

const getters = {
    disciplinesLoadedOnce: state => state.loadedOnce,
    disciplines: state => state.disciplines.sort((a, b) => a.label.localeCompare(b.label)),
    disciplineSelectOptions: state => state.disciplines.map(discipline => ({ value: discipline.uuid, label: discipline.label })).sort((a, b) => {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1; //nameA comes first
        }
        if (nameA > nameB) {
            return 1; // nameB comes first
        }
        return 0;  // names must be equal
    }),
    disciplineOptionFilterUuid: (state) => uuid => state.disciplines.find(deg => deg.uuid == uuid),
    disciplineByUuid: state => uuid => state.disciplines.find(d => d.uuid == uuid)
}

const actions = {
    async getDisciplines({ commit }) {
        commit("GET_DISCIPLINES_REQUEST")
        const response = await disciplineService.getDisciplines();
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_DISCIPLINES_SUCCESS", data)
                break
            default:
                commit("GET_DISCIPLINES_ERROR", data)
        }
        return response
    },
    async getDiscipline({ commit }, params) {
        commit("GET_DISCIPLINE_REQUEST")
        const response = await disciplineService.getDiscipline(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("GET_DISCIPLINE_SUCCESS", data)
                break
            default:
                commit("GET_DISCIPLINE_ERROR", data)
                break
        }
        return response
    },
    async createDiscipline({ commit }, params) {
        commit("CREATE_DISCIPLINE_REQUEST")
        const response = await disciplineService.createDiscipline(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("CREATE_DISCIPLINE_SUCCESS", data)
                break
            default:
                commit("CREATE_DISCIPLINE_ERROR", data)
        }
        return response
    },
    async editDiscipline({ commit }, params, uuid) {
        commit("EDIT_DISCIPLINE_REQUEST")
        const response = await disciplineService.editDiscipline(params, uuid)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("EDIT_DISCIPLINE_SUCCESS", data)
                break
            default:
                commit("EDIT_DISCIPLINE_ERROR", data)
        }
        return response
    },
    async deleteDiscipline({ commit }, params) {
        commit("DELETE_DISCIPLINE_REQUEST")
        const response = await disciplineService.deleteDiscipline(params)
        const { status, data } = response
        switch (status) {
            case 200:
                commit("DELETE_DISCIPLINE_SUCCESS", data)
                break
            default:
                commit("DELETE_DISCIPLINE_DELETE", data)
        }
        return response
    }
}

const mutations = {
    [GET_DISCIPLINES_REQUEST]: state => {
        state.loading = true
    },
    [GET_DISCIPLINES_SUCCESS]: (state, data) => {
        const disciplines = data.disciplines
        state.disciplines = disciplines
        state.loading = false
        state.loadedOnce = true
    },
    [GET_DISCIPLINES_ERROR]: state => {
        state.loading = false
        state.error = true
        state.loadedOnce = true
    },
    [GET_DISCIPLINE_REQUEST]: state => {
        state.loading = true
    },
    [GET_DISCIPLINE_SUCCESS]: (state, data) => {
        const { discipline }  = data
        state.discipline = discipline
        state.loading = false
    },
    [GET_DISCIPLINE_ERROR]: (state, data) => {
        state.loading = false
    },

    [CREATE_DISCIPLINE_REQUEST]: state => {
        state.loading = true
    },
    [CREATE_DISCIPLINE_SUCCESS]: (state, data) => {
        const { discipline } = data
        state.disciplines = addNewFilter(state.disciplines, [discipline])
        state.loading = false
    },
    [CREATE_DISCIPLINE_ERROR]: state => {
        state.loading = false
    },

    [EDIT_DISCIPLINE_REQUEST]: state => {
        state.loading = true
    },
    [EDIT_DISCIPLINE_SUCCESS]: (state, data) => {
        const { discipline } = data
        state.disciplines = state.disciplines.map(d => d.uuid == discipline.uuid ? discipline : d )
        state.loading = false
    },
    [EDIT_DISCIPLINE_ERROR]: state => {
        state.loading = false
    },

    [DELETE_DISCIPLINE_REQUEST]: state => {
        state.loading = true
    },
    [DELETE_DISCIPLINE_SUCCESS]: (state, data) => {
        state.loading = false
    },
    [DELETE_DISCIPLINE_ERROR]: (state, data) => {
        state.loading = false
    }

}

export const discipline = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
