import moment from "moment"

/**
 *
 * @param {object} input
 * @returns FormData
 */
export function processInput(input) {

  function setKeyValue(k, v, data) {
    let type = typeof input[k];

    type = Array.isArray(input[k]) ?
      'array' :
      Object.prototype.toString.call(input[k]) === '[object Object]' ?
        'object' :
        Object.prototype.toString.call(input[k]) === '[object Null]' ?
          null : type;

    switch (type) {
      case 'boolean':
        if (v) {
          data.append(k, 1)
        } else {
          data.append(k, 0)
        }
        break
      case 'object':
        //if the object is a file
        if (('src' in v) && typeof v['src'] == 'string' && v['src'].startsWith('blob')) {
          data.append(k, v);
        } else {
          Object.keys(v).forEach((key) => {
            setKeyValue(`${k}[${key}]`, v[key], data)
          })
        }
        break;
      case 'array':
        v.length !== 0 ? v.forEach(value => {
          typeof value === 'string' || value instanceof String || value instanceof File ?
            data.append(`${k}[]`, value) :
            data.append(`${k}[]`, JSON.stringify(value))
        }) : data.append(`${k}`, [])
        break;
      case null:
        data.append(k, "");
        break
      default:
        // eslint-disable-next-line no-case-declarations
        let appendVal = (typeof v != 'undefined') ? v : null
        if (appendVal !== null) data.append(k, appendVal);
    }
  }

  let data = new FormData();

  Object.keys(input).forEach((k) => {
    setKeyValue(k, input[k], data)
  })

  return data;
}

export function processQueryParams(params) {
  let data = new URLSearchParams();

  if (isEmpty(params)) {
    return data
  }

  Object.keys(params).forEach((k) => {

    let type = typeof params[k];

    type = Array.isArray(params[k]) ? 'array' : type;

    switch (type) {
      case 'object':
        data.append(k, JSON.stringify(params[k]));
        break;
      case 'array':
        params[k].forEach((value) => {
          // data.append(`${k}[]`, index)
          typeof value === 'string' || value instanceof String || value instanceof File ?
            data.append(`${k}[]`, value) :
            data.append(`${k}[]`, JSON.stringify(value))
        })
        break;
      default:
        data.append(k, params[k]);
    }
  });
  
  return data;
}

/**
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

/**
 *
 * @param {*} string
 * @returns
 */
export function generateKey() {
  // return Date.now().toString(36) + Math.random().toString(36);
  // const array = new Uint32Array(10);
  // return self.crypto.getRandomValues(array)[0] * self.crypto.getRandomValues(array)[1]
  // return self.crypto.randomUUID();
  return Number(new Date()) + Math.floor(Math.random() * 100000);
}

export function sortBy(a, b) {
  const aObject = a.number,
    bObject = b.number;

  let comparison = 0;
  if (aObject > bObject) {
    comparison = 1;
  } else if (aObject < bObject) {
    comparison = -1;
  }
  return comparison;
}

export function findObjectByKeyValue(key, value, array) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].key && array[i].key === value) {
      return array[i];
    }
  }
}

export function differenceBetween(largerArray, smallerArray) {
  let s = new Set(smallerArray)
  return [...largerArray].filter(x => !s.has(x))
}

export function findUpdatedItem(currentArray, newArray, checkItems) {
  let updatedKeys = []
  for (let k in newArray) {
    if (!updatedKeys.includes(k)) {
      checkItems.forEach(i => {
        if (typeof currentArray[k] == typeof undefined) {
          if (!updatedKeys.includes(k)) {
            updatedKeys.push(k)
          }
        } else {
          if (currentArray[k][i] != newArray[k][i]) {
            if (!updatedKeys.includes(k)) {
              updatedKeys.push(k)
            }
          }
        }
      })
    }
  }
  return updatedKeys
}

/**
 * Adds data objects that don't exist in an array of existing objects.
 *
 * @param {Array} existingData
 * @param {Array} newData
 * @param {Boolean} forceUpdate
 * @returns {Array}
 */
export function addNewFilter(existingData, newData, forceUpdate, uniqueKey) {
  forceUpdate = forceUpdate ?? false;

  uniqueKey = uniqueKey ?? 'uuid';

  if (!Array.isArray(existingData) || !Array.isArray(newData)) {
    return new Array(0)
  }

  let resArray = new Array(0);

  // newData = newData.filter(i => i);

  // Filter out any old records
  resArray = existingData.filter(a => (isEmpty(a) || a == null) ? false : !newData.some(b => !isEmpty(b) && a[uniqueKey] == b[uniqueKey]));
  
  // Return filtered array plus any new records.
  return resArray.concat(newData)
}

export async function localStorageGetter(itemName) {
  return new Promise((res, rej) => {
    try {
      const item = window.localStorage.getItem(itemName);
      return res(item)
    } catch (error) {
      return rej(error);
    }
  });
}

/**
 *
 * @param {*} key
 * @param {*} value
 * @returns
 */
export async function localStorageSetter(key, value) {
  return new Promise((res, rej) => {
    try {
      window.localStorage.setItem(key, value);
      return res(true);
    } catch (error) {
      return rej(error);
    }
  });
}


export function localStorageRemover(key) {
  return new Promise((res, rej) => {
    try {
      window.localStorage.removeItem(key);
      return res(true)
    } catch (error) {
      return rej(error);
    }
  })
}

export function localStorageArray(way, key, val) {
  if (way === 'get') {
    let data = localStorage.getItem(key)
    if (!isEmpty(data)) {
      return data.split(',')
    }
    return []
  } else if (way === 'set' || way === 'remove') {
    let data = localStorage.getItem(key)
    if (!isEmpty(data)) {
      data = data.split(',')
    } else {
      data = []
    }
    if (way === 'set' && !data.some(d => d == val)) {
      data.push(val)
    } else if (way === 'remove' && data.some(d => d == val)) {
      data = data.filter(d => d != val)
    }
    localStorage.setItem(key, data)
    return data
  }
  return []
}





/**
 *
 * @param {*} date
 * @param {*} outputFormat
 * @returns
 */
export function dateTimeFormat(date, outputFormat) {
  return moment(date).format(outputFormat)
}

/**
 *
 *
 * @param {Object} date
 * @param {number} days
 *
 * @return {Date}
 */
export function addDays(date, days) {
  let d = new Date(date)
  let v = new Date(Number(d))
  v.setDate(d.getDate() + days)
  return v
}

/**
 *
 * @param {*} date
 * @param {*} addSubDays
 * @param {*} outputFormat
 * @returns {Date}
 */
export function momentDate(date, addSubDays = 0, outputFormat = null) {
  let ms = new Date(moment(date)).getTime() + (86400000 * addSubDays);
  if (outputFormat) {
    return moment(ms).format(outputFormat);
  }
  return new Date(ms);
}

/**
 *
 * @param {*} mysqlDateTimeValue
 * @returns Date
 */
export function safariDateSanitizer(mysqlDateTimeValue) {
  if (isEmpty(mysqlDateTimeValue)) return ""
  return Date.parse(mysqlDateTimeValue.replace(/[-]/g, '/'))
}



/**
 *
 * @param {*} value
 * @param {*} index
 * @param {*} self
 * @returns
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export function onlyUniqueByKey(value, index, self, key) {
  return self.indexOf(value[key]) === index
}

/**
 *
 * @param {*} time
 * @returns
 */
export function timeDifference(time) {
  let timeStamp = Number(new Date(moment(time).format()))
  let current = Number(new Date())
  let msPerMinute = 60 * 1000
  let msPerHour = msPerMinute * 60
  let msPerDay = msPerHour * 24
  let msPerMonth = msPerDay * 30
  let elapsed = current - (timeStamp)
  let string = ''
  if (elapsed < msPerMinute) {
    if (Math.round(elapsed / 1000) <= 10) {
      string = 'Just now'
    } else {
      string = Math.round(elapsed / 1000) + ' seconds ago'
    }
  } else if (elapsed < msPerHour) {
    if (Math.round(elapsed / msPerMinute) == 1) {
      string = 'A minute ago'
    } else {
      string = Math.round(elapsed / msPerMinute) + ' minutes ago'
    }
  } else if (elapsed < msPerDay) {
    if (Math.round(elapsed / msPerHour) == 1) {
      string = 'An hour ago'
    } else {
      string = Math.round(elapsed / msPerHour) + ' hours ago'
    }
  } else if (elapsed < msPerMonth) {
    if (Math.round(elapsed / msPerDay) > 7) {
      string = dateTimeFormat(time, "MM/D/YY, h:mm:ss A")
    } else {
      if (Math.round(elapsed / msPerDay) == 1) {
        string = 'A day ago'
      } else {
        if (Math.round(elapsed / msPerDay) == 7) {
          string = 'A week ago'
        } else {
          string = Math.round(elapsed / msPerDay) + ' days ago'
        }
      }
    }
  } else {
    string = dateTimeFormat(time, "MM/D/YY, h:mm:ss A")
  }
  return string;
}


export function decodeLaravelValidationErrors(Errs) {
  let errors = []

  if (typeof Errs === "string") {
    return Errs;
  }

  if (typeof Errs !== 'object') return false

  if (Object.keys(Errs).length === 0) return false

  for (let key in Errs) {
    if (Errs[key].length > 0) {
      for (let ek in Errs[key]) {
        errors.push(Errs[key][ek])
      }
    }
  }

  if (errors.length === 0) return false

  return errors
}

export function limitTo(value, length = 30) {
  if (!value) return '';
  if (value.length > length) {
    return value.substring(0, length) + '...';
  } else {
    return value;
  }
}

/**
 *
 * @param {*} value
 * @returns
 */
export function isNumeric(value = null) {
  if (isEmpty(value)) {
    return false
  }
  return !isNaN(parseInt(value))
}

/**
 *
 * @param {*} variable
 */
export function isObject(variable) {
  return Object.prototype.toString.call(variable) === '[object Object]';
}

/**
 *
 * @param {*} variable
 * @returns Boolean
 */
export function isString(variable) {
  return Object.prototype.toString.call(variable) === "[object String]"
}





export function copyClipboard() {

}


/**
 *
 * @param {String} string
 * @returns {String}
 */
export function removeWhiteSpaceSlug(string) {
  if (typeof string !== 'string') {
    return string
  }

  if (isEmpty(string)) {
    return null
  }

  return string.trim().replace(/-|\s/g, '').toUpperCase()
}
