import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  backgroundCheckRoutes,
  coachRegistration,
  coachRegistrationResume,
  coachApplication,
  coachPortal,
  facultySourceRoleRoutes,
  groupRoutes,
  invoiceRoutes,
  invoiceAdjustmentRoutes,
  payrollRoutes,
  reportsRoutes,
  roleRoutes,
  settingRoutes,
  universityPartnerRoutes,
  universityPortalRoutes,
  universityCoachRequest,
  wasMeetingMinutes,
  csMeetingMinutes,
  coachEvaluation,
  facultyEvaluation,
  universityPartnershipLink,
  universityRoutes,
  universityStatusRoutes,
  universityOnboardingRoutes,
} from "@/router";
import { store } from '@/store'
import { isEmpty } from "@/utilities"

Vue.use(VueRouter)

const routes = [
  // {
  //   path: "/",
  //   name: "welcome",
  //   component: () => import("@/pages/WelcomePage.vue")
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/LoginPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    component: () => import("@/pages/auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: () => import("@/pages/auth/ResetPassword.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/auth/RegisterPage.vue")
  },
  {
    ...facultyEvaluation
  },
  {
    ...universityPartnerRoutes
  },
  {
    ...universityPortalRoutes
  },
  {
    path: "/coach-application/auth-error",
    name: "coach-application auth-error",
    component: () => import("@/pages/error/CoachApplicantLoginError.vue")
  },
  {
    path: '/coach-portal/auth-error',
    name: 'coach-portal auth-error',
    meta: {
      label: "Coach Portal Auth Error"
    },
    component: () => import("@/pages/error/CoachPortalAuthError.vue")
  },
  {
    path: "/",
    component: () => import("@/layouts/Admin.vue"),
    meta: {
      authRequired: true,
      middleware: [

      ],
      allowedRoles: [
        'super-admin',
        'admin',
        'operations',
        'lead-coach',
        'program-assistant',
        'executive',
      ]
    },
    children: [
      {
        path: "",
        name: "dashboard",
        meta: {
          label: "Dashboard",
        },
        component: () => import("@/pages/DashboardHome.vue")
      },
      backgroundCheckRoutes,
      {
        path: "applicant-center",
        name: "applicant-center",
        meta: {
          label: "Applicant Center"
        },
        component: () => import("@/pages/coach/admin/CoachApplicantCenter.vue")
      },
      {
        path: "comments",
        name: "all comments",
        meta: {
          label: "Comments"
        },
        component: () => import("@/pages/comments/AppComments.vue")
      },
      settingRoutes,
      {
        path: "coaches",
        component: () => import("@/pages/coach/admin/CoachContainer.vue"),
        children: [
          {
            path: "",
            name: "coaches",
            meta: {
              label: "Contracted, Pending & Active Coaches"
            },
            component: () => import("@/pages/coach/admin/CoachSearchContainer.vue")
          },
          {
            path: ":uuid",
            name: "coach details",
            meta: {
              label: "Coach Profile"
            },
            component: () => import("@/pages/coach/admin/CoachDetailsContainer.vue")
          },
          {
            path: ":uuid/comments",
            name: "coach comments",
            meta: {
              label: "Coach comments"
            },
            component: () => import("@/pages/coach/admin/CoachViewAllComments.vue")
          }
        ]
      },
      {
        path: "screening-sessions",
        component: () => import("@/pages/screening-sessions/ScreeningSessionsContainer.vue"),
        children: [
          {
            path: "",
            name: "screening-session index",
            meta: {
              label: "Screening Sessions"
            },
            component: () => import("@/pages/screening-sessions/ScreeningSessionsIndex.vue")
          },
          {
            path: ":uuid",
            name: "screening-session details",
            meta: {
              label: "Screening Session"
            },
            component: () => import("@/pages/screening-sessions/ScreeningSessionsDetail.vue")
          }
        ]
      },
      {
        path: 'users',
        component: () => import("@/pages/users/UsersHome.vue"),
        children: [
          {
            path: '',
            name: 'users',
            meta: {
              label: 'Users'
            },
            component: () => import("@/pages/users/UsersIndex.vue")
          },
          {
            path: ':uuid',
            name: 'user detail',
            meta: {
              label: 'User Details'
            },
            component: () => import("@/pages/users/UsersDetail.vue"),
          },
          {
            path: 'create/new',
            name: 'user create',
            meta: {
              label: 'Create User'
            },
            component: () => import("@/pages/users/EditUser.vue"),
          },
        ]
      },
      {
        ...universityRoutes
      },
      {
        ...universityStatusRoutes
      },
      {
        ...universityOnboardingRoutes
      },
      {
        ...facultySourceRoleRoutes
      },
      {
        ...groupRoutes
      },
      {
        ...reportsRoutes
      },
      {
        ...roleRoutes
      },
      {
        ...wasMeetingMinutes
      },
      {
        ...csMeetingMinutes
      },
      {
        ...coachEvaluation
      },
      ...payrollRoutes,
      {
        path: 'lead-coach-assignments',
        name: 'lead-coach assignments',
        meta: {
          label: 'Lead Coach Assignments'
        },
        component: () => import("@/pages/users/LeadCoachAssignments.vue"),
      },
      invoiceRoutes,
      
      invoiceAdjustmentRoutes
      
    ]
  },
  {
    ...coachApplication
  },
  {
    ...coachPortal
  },
  {
    ...coachRegistration
  },
  {
    ...coachRegistrationResume
  },
  {
    ...universityPartnershipLink
  },
  {
    ...universityCoachRequest
  },
  {
    path: '/screening-onboarding-process',
    name: 'screening onboarding process',
    component: () => import("@/pages/misc/ScreeningOnboardingProcess.vue")
  },
  {
    path: '/official-transcripts',
    name: 'official transcripts',
    component: () => import("@/pages/misc/OfficialTranscripts.vue")
  },
  {
    path: "/404",
    name: '404',
    component: () => import("@/pages/Page404.vue"),
    meta: {
      label: "Not Found"
    }
  },
  {
    path: "*",
    component: () => import("@/pages/Page404.vue"),
    name: 'not found',
    meta: {
      label: "Not Found"
    }
  }
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  // if (to.query.redirect) next({ path: to.query.redirect })

  if (to.matched.some(record => record.meta.authRequired)) {
    //Check to see if our session is still valid before we proceed
    const response = await store.dispatch('auth/getAuthUser', null, { root: true });

    const { status, error, data } = response
    
    //if not valid

    //
    // Catch ALL for anything NOT 200
    //
    let pathSegements = to.path.split("/")

    if (status !== 200) {
      console.error("Auth Error")

      if (!isEmpty(pathSegements) && pathSegements.includes('coach-application')) {
        next({
          name: "coach-application auth-error"
        })
        return
      }

      if (!isEmpty(pathSegements) && pathSegements.includes('coach-portal')) {
        next({
          name: "coach-portal auth-error"
        })

        return
      }

      let redirect = {
        name: 'login',
      }

      if (to.name != 'dashboard') {
        redirect['query'] = {
          redirect: to.fullPath
        }
      }

      // next({

      //     query: { redirect: to.fullPath }
      // })
      next(redirect)
      return;
    }

    store.commit('updateCurrentPath', to, { root: true })
    const { roles, password_is_set, coaches_uuid, meta } = data.user
    if (pathSegements.some(ps => ps == 'coach-portal')) {
      // Tells the app our current path to get from state
      // Used in Coach Application progress re-direct
      let isCoach = false
      if (roles.some(r => r == 'coach')) {
        isCoach = true
        const coachLoad = [
          'background_checks',
          'education',
          'documents',
          'nurse_licenses',
          'comments',
          'addresses',
          'emails',
          'phones',
          'specialties',
          'employments',
          'education_experience',
          'certifications',
          'lms_experience',
          'screening_sessions',
          'references',
          'eeo',
          'facilitating_courses',
          'languages',
          'teaching_course_levels',
          'teaching_profile',
        ];
        // Only set Coach once 
        // Watchers might reload unnecessarily. 
        if (isEmpty(store.getters['coaches/coach'])) {
          const red = await store.dispatch('coaches/getCoach', {
            uuid: coaches_uuid,
            append: ["employee_id", 'application'],
            load: coachLoad
          }, { root: true })
        }
      }
    }

    if(!isEmpty(meta?.university_uuid) && roles.some(r => r == 'university-admin')){
      // Only set Coach once 
      // Watchers might reload unnecessarily. 
      if (isEmpty(store.getters['university/university'])) {
        await store.dispatch('university/getUniversity', {
          uuid: meta.university_uuid,
          load: [
            'procedure',
            'programs.courses',
            'courseStart.pre_course_meeting_minute',
            'courseStart.course_meeting_minutes',
            'courseStart.faculty',
            'courseStart.sections',
          ],
          in_direct_load: [
            'courseStart.sections',
            'courseStart.pre_course_meeting_minute',
            'courseStart.course_meeting_minutes',
            'courseStart.faculty',
          ],
          append: [
            'program.future_was_resource',
            'program.current_was_resource',
            'wasFuture.course_starts',
            'coach_alternative_title',
          ]
        }, { root: true })
      }
    }
    // User will/shoudld have an array of roles
    // Generally roles.length should only be 1.

    if (!isEmpty(pathSegements) && pathSegements.includes('coach-portal')) {

      let notAllowedStatusSlugs = [
        'applicant',
        'candidate',
        'resigned',
        'dnua',
      ]
      let coach = store.getters['coaches/coach']

      //This is incorrect - you need to get status from the store NOT the coach. 
      // if(!isEmpty(coach.status)){
      //     if(!isEmpty(coach.status.status)){
      //         if(!isEmpty(coach.status.status.slug)){
      //             let checkSlug = coach.status.status.slug.toLowerCase()
      //             if(notAllowedStatusSlugs.includes(checkSlug)){
      //                 store.dispatch('auth/loginError', 'You\'re not authorized to login in coach portal', {root: true})
      //                 next({
      //                     name: 'login'
      //                 })
      //             }
      //         }
      //     }
      // }
    }

    //If User is a Coach update state with coach data from user object

    // store.dispatch('task/getTaskByUserUuid', data.user.uuid, { root: true })

    //Check to See if the user's roles allow them to this section. 
    to.matched.every(segment => {
      if (!isEmpty(segment.meta.allowedRoles) && !roles.some(r => segment.meta.allowedRoles.includes(r))) {
        console.log(segment.meta.allowedRoles, roles)
        store.dispatch('auth/logout', null, { root: true })
        next({
          name: 'login'
        })
        return false
      }
      return true
    })
  }

  next() // make sure to always call next()!
});