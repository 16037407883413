import { isEmpty } from '@/utilities'
const state = {
	portalMenu: [
		{
			order: 0,
			disabled: false,
			slug: "link",
			name: "Dashboard",
			icon: "cil-home",
			to: {
				name: 'coach-portal-dashboard'
			},
		},
		{
			order: 2,
			disabled: false,
			slug: "link",
			name: "Past Assignments",
			icon: "cil-folder",
			to: {
				name: 'coach-portal-past-assignments'
			},
		},
		{
			order: 3,
			disabled: true,
			slug: "link",
			name: "Past Payments",
			icon: "cil-money",
			to: {
				name: 'coach-portal-past-payments'
			},
		},
		{
			order: 4,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Documents",
			icon: "cil-file",
			to: {
				name: 'coach-portal-documents'
			},
		},
		{
			order: 5,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Education",
			icon: "cil-file",
			to: {
				name: 'coach-portal-education'
			},
		},
		{
			order: 6,
			disabled: false,
			viewable: true,
			slug: "link",
			name: "Nurse License",
			icon: "cil-file",
			to: {
				name: 'coach-portal-nurse-license'
			},
		},
        {
			order: 7,
			disabled: false,
			slug: "link",
			name: "My Account",
			icon: "cil-lock-locked",
			to: {
				name: 'coach-portal-acc'
			},
		},
	],
}

const getters = {
	coachPortalMenu: (state, getters, rootState, rootGetters) => {
		const specialtyBySlug = rootGetters['specialty/specialtyBySlug']
		const nursing = specialtyBySlug('nursing')
		if(isEmpty(nursing)) return state.portalMenu;
		const coachSpecialties = rootGetters['coachSpecialties/coachSpecialties']
		const nursingSp = coachSpecialties.some(i => i.specialties_uuid == nursing.uuid)
		return state.portalMenu.map(i => {
			if(i.order == 6){
				if(nursingSp){
					return {
						...i,
						disabled: false,
						viewable: true
					}
				}
			}
			return i
		})
	},
}

const actions = {

}

const mutations = {

}

export const coachPortal = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
